<!-- 404页面 -->
<template>
  <div class="wrap_404">

      <img src="../../assets/imgs/404.png" alt="" class="img_404">
      <h2>抱歉，您访问的页面出错了</h2>
      <h3>该网页可能已删除或者不存在</h3>
      <div class="index_btn">
          <router-link :to="{name:'Search'}">返回首页</router-link>
      </div>



  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  created(){},

  methods: {}
}

</script>
<style lang='less' scoped>
.wrap_404 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
}
.img_404 {
    width: 550px;
    margin-top: 100px;
}
h2 {
    line-height: 40px;
    font-size: 19px;
    color: #5c5c5c;
}
h3 {
    line-height: 50px;
    font-size: 14px;
    color: #5c5c5c;
}

.index_btn {
    margin-top: 20px;
    border-radius: 4px;
    padding: 0 30px;
    line-height: 40px;
    color: #fff;
    background: #03C1BD;
    a {
        color: #fff;
    }

}
</style>